<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="custom-width">
            <!-- Brand logo-->
            <div class="d-flex justify-content-between">
              <b-link class="brand-logo-update">
                <DefaultLogo />
              </b-link>
              <ul class="lang-sign-up">
                <locale />
              </ul>
            </div>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold bt-40">
              {{ $t("login.Welcome") }}
            </b-card-title>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group :label="$t('login.Username')" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    vid="Username"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge user-valid-23"
                      :class="{
                        'is-invalid': errors.length > 0,
                        'is-valid': errors.length === 0 && user.username
                      }"
                    >
                      <b-input-group-prepend
                        is-text
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid': errors.length === 0 && user.username
                        }"
                      >
                        <feather-icon icon="UserIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="username"
                        v-model="user.username"
                        :class="
                          errors.length == 0 && user.username
                            ? 'is-valid'
                            : null
                        "
                        :style="
                          user.username ? 'text-transform: lowercase' : ''
                        "
                        :state="errors.length > 0 ? false : null"
                        name="username"
                        :placeholder="$t('login.RequiredUserName')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group :label="$t('login.Pass')" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="{
                        'is-invalid': errors.length > 0,
                        'is-valid': errors.length === 0 && user.password
                      }"
                    >
                      <b-input-group-prepend
                        is-text
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid': errors.length === 0 && user.username
                        }"
                      >
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="password"
                        v-model="user.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :class="
                          errors.length == 0 && user.password
                            ? 'is-valid'
                            : null
                        "
                        name="password"
                        :placeholder="$t('login.RequiredPassword')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div
                    class="d-flex justify-content-between mb-1"
                    :class="isDisableForgotPasswordBtn ? 'disabled' : ''"
                  >
                    <b-form-checkbox
                      v-model="rememberMe"
                      class="form-control-merge cursor-pointer"
                    >
                      {{ $t("RememberMe") }}
                    </b-form-checkbox>
                    <label for="login-password"></label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t("login.ForgotPass") }}</small>
                    </b-link>
                  </div>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t("login.Login") }}
                </b-button>

                <div style="margin-top: 15px" v-if="hasErr && errorTitle">
                  <b-alert show variant="danger" class="p-1">
                    <h4 class="text-danger">{{ errorTitle }}</h4>
                    <p>{{ hasErr }}</p>
                  </b-alert>
                </div>
                <div
                  style="margin-top: 15px"
                  v-if="verifyMessage && isVerified"
                >
                  <b-alert show variant="success" class="p-1">
                    <h4 class="align-items-center">{{ verifyMessage }}</h4>
                  </b-alert>
                </div>
              </b-form>
            </validation-observer>
            <div class="text-center mt-3">
              <span class="get-app">
                {{ $t("login.GetApp") }}
              </span>
            </div>
            <div class="d-flex flex-sm-row align-items-center mt-1 p-2">
              <b-link
                href="https://apps.apple.com/us/app/iot-polygon/id6502901501"
                target="_blank"
                class="blink1"
              >
                <b-img
                  src="../assets/images/pages/login/appstore.png"
                  width="160px"
                ></b-img>
              </b-link>
              <b-link
                href="https://play.google.com/store/apps/details?id=com.enovateit.polygon"
                target="_blank"
                class="blink2"
              >
                <b-img
                  src="../assets/images/pages/login/googleplay.png"
                  width="160px"
                ></b-img>
              </b-link>
            </div>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>

      <!-- /Left Text-->
    </b-row>
    <SwitchAccount
      :accounts="accounts"
      :isSelectedId="isSelectedId"
      :mouseout="mouseout"
      :mouseover="mouseover"
      :selectedUserAccount="selectedUserAccount"
    />

    <Loader :show="show" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { v4 as uuid } from "uuid";
import firebaseApp from "../../src/utils/firebase";
import { getMessaging, getToken } from "firebase/messaging";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import Locale from "@/layouts/components/Locale.vue";
import SwitchAccount from "@/layouts/components/SwitchAccount.vue";
import AccountService from "@/libs/api/account-service";
import { localeChanged } from "vee-validate";
import Menus from "@/navigation/vertical/index";
import acl from "@/utils/acl";
import Loader from "@/layouts/components/Loader.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend,
  OverlayPlugin,
  BOverlay,
  BAlert
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import useJwt from "@/auth/jwt/useJwt";
import constants from "@/utils/constants";
import AuthService from "@/libs/api/auth-service";
import LoginImage from "@/assets/images/pages/login-v2.svg";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    OverlayPlugin,
    BOverlay,
    BInputGroupPrepend,
    LoginImage,
    BAlert,
    Locale,
    SwitchAccount,
    Loader
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      show: false,
      hasErr: "",
      errorTitle: "",
      rememberMe: false,
      isDisableForgotPasswordBtn: false,
      accounts: [],
      verifyMessage: "",
      user: {
        password: "",
        username: this.$route.query.username ? this.$route.query.username : ""
      },
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      isVerified: false,

      activeUser: "select-account-row active-primary",
      isSelectedId: "",
      isOldSelectedId: "",
      modalShow: "modal-sm-switch-account",
      downloadUrl: process.env.VUE_APP_FILE_URL,
      inviteData: {},
      isInvitation: false,
      loggedUserId: "",
      accountName: localStorage.getItem("USER_ACCOUNT_NAME"),
      roleName: localStorage.getItem("USER_ACCOUNT_ROLE_NAME")
    };
  },
  mounted() {
    if (this.$route.name === "verify-email") {
      this.verifyEmail(this.$route.query);
    }
    this.hasErr = "";
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    ...mapMutations("user", ["setUserAccount"]),
    ...mapActions(["setUser"]),
    async validationForm() {
      let hasErr = "";
      if (!this.user.username) {
        this.hasErr = this.$t("login.RequiredUserName");
      } else if (!this.user.password) {
        this.hasErr = this.$t("login.RequiredPassword");
      }
      if (hasErr && hasErr !== "") {
        this.hasErr = hasErr;
        return;
      }
      try {
        this.show = true;
        let sessionId = localStorage.getItem("sessionId");
        if (!sessionId) {
          localStorage.setItem("sessionId", uuidv4());
        }
        const messaging = getMessaging(firebaseApp);
        let notiToken = null;
        try {
          if ("Notification" in window) {
            if (Notification && Notification.permission === "granted") {
              await getToken(messaging, {
                vapidKey:
                  "BG2plIQHsAObZUznnNLjC5nYXFDmC6inHatRvnbjccuagsPpcwkKibJT2adzWiBdAlgb4_cCzfmRShivYzVe4aY"
              })
                .then((currentToken) => {
                  if (currentToken) {
                    notiToken = currentToken;
                    localStorage.setItem("notificationToken", currentToken);
                  }
                })
                .catch((err) => {
                  console.log("error while notification token: ", err.message);
                  localStorage.removeItem("notificationToken");
                });
            } else {
              localStorage.removeItem("notificationToken");
            }
          } else {
            localStorage.removeItem("notificationToken");
          }
        } catch (err) {
          localStorage.removeItem("notificationToken");
        }
        let object = {
          username: this.user.username.toLowerCase(),
          password: this.user.password,
          session_id: localStorage.getItem("sessionId")
        };
        if (notiToken) {
          object.notification_token = notiToken;
        }
        if (this.rememberMe) {
          object.remember = true;
        }
        let response = await new AuthService().login(object);
        if (response && response.data && response.data.user_token) {
          this.isDisableForgotPasswordBtn = true;
          this.show = false;
          this.hasErr = "";
          this.errorTitle = "";
          localStorage.setItem(
            useJwt.jwtConfig.storageTokenKeyName,
            response.data.user_token
          );
          if (this.rememberMe) {
            if (response.data.bearer_token) {
              localStorage.setItem("BEARER_TOKEN", response.data.bearer_token);
            }
          }
          this.inviteData =
            localStorage.getItem("inviteData") &&
            JSON.parse(localStorage.getItem("inviteData"));

          this.accounts = response.data.accounts;
          this.setUserAccount(response.data.user);
          localStorage.setItem(
            "USER_PROFILE_DETAILS",
            JSON.stringify(response.data.user)
          );
          this.loggedUserId = response.data.user.id;
          this.getAccounts();
          // if (response.data.active_account_count) {
          //   this.$router.push({ name: "home" }).catch((e) => {});
          // } else {
          //   this.$router.push({ name: "user-invitations" }).catch((e) => {});
          // }

          // this.$router.go();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.hasErr = response.error.message;
          this.errorTitle = response.error.title;
        } else {
          this.show = false;
          this.hasErr = response.error.message;
          this.errorTitle = response.error.title;
        }
      } catch (err) {
        this.show = false;
        this.hasErr = "err.message";
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      document.body.classList.remove(
        "blue-layout",
        "pink-layout",
        "red-layout",
        "orange-layout",
        "yellow-layout",
        "green-layout",
        "teal-layout",
        "cyan-layout"
      );
      document.body.classList.add(className);
      localStorage.setItem("savedClass", className);
    },

    async verifyEmail(data) {
      try {
        this.show = true;
        let requestData = {
          code: data.code,
          token: data.token
        };
        let response = await new AuthService().verifyEmail(requestData);
        this.show = false;
        if (response && response.data) {
          this.isVerified = true;
          this.verifyMessage = this.$t("profile.VerifyEmail");
          await new AuthService().logout({
            session_id: localStorage.getItem("sessionId"),
            token: localStorage.getItem("accessToken")
          });
          setTimeout(() => {
            this.verifyMessage = "";
            this.$router.push({ name: "login" });
          }, 2000);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    mouseover(id) {
      this.isSelectedId = id;
    },
    mouseout(id) {
      this.isSelectedId = this.isOldSelectedId;
    },
    async selectedUserAccount(account, isRedirect) {
      try {
        const accountId = localStorage.getItem("OLD_USER_ACCOUNT");
        const requestData = {};
        if (
          !accountId ||
          accountId != account.account_id ||
          !localStorage.getItem("SESSION_LOGIN")
        ) {
          const newSession = uuidv4();
          const oldSession = localStorage.getItem("SESSION_LOGIN");
          localStorage.setItem("SESSION_LOGIN", newSession);
          if (oldSession) {
            requestData.old_session = oldSession;
          }

          requestData.new_session = newSession;
          requestData.account_id = account.account_id;
          if (accountId) {
            requestData.old_account_id = accountId;
          }
        }
        localStorage.setItem("USER_ACCOUNT_ID", account.account_id);
        localStorage.setItem(
          "USER_SUB_ACCOUNTS_RESTRICTED",
          account.restrict_sub_accounts
        );
        localStorage.setItem("ACCOUNT_ID", account.account_id);
        localStorage.setItem("USER_ACCOUNT_TYPE", account.type);

        store.commit("account/setAccountType", account.type);
        localStorage.setItem("USER_ACCOUNT_ROLE_NAME", account.role);
        localStorage.setItem("USER_ACCOUNT_NAME", account.account_name);
        localStorage.setItem("USER_ACCOUNT_ROLE_ID", account.main_role);
        if (this.loggedUserId) {
          localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
        }

        this.whiteLabeling(account);
        this.isOldSelectedId = account.account_id;
        this.accountName = account.account_name;
        // call select account api.
        let response = await new AccountService().selectAccount({
          account_id: account.account_id,
          ...requestData
        });
        store.commit("authorized/SET_IS_LOGIN", true);
        if (response && response.data) {
          if (localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true") {
            if (
              ["DEALER", "CONSUMER"].includes(
                localStorage.getItem("USER_ACCOUNT_TYPE")
              )
            ) {
              this.getFilteredLists(["account", "account_devices"], response); //pass in array which we want to exclude
            }
          } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
            this.getFilteredLists(["devices"], response);
          }
          const oldPermission = localStorage.getItem("USER_PERMISSIONS");
          acl.parseAndStoreAbilities(response.data, false, true);
          const newPermission = localStorage.getItem("USER_PERMISSIONS");
          this.$bvModal.hide("modal-sm-switch-account");
          if (
            this.checkAbility({
              action: constants.LABELS.MENU,
              subject: "units"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            const query = localStorage.getItem("USER_ACCOUNT_ID")
              ? { account_id: localStorage.getItem("USER_ACCOUNT_ID") }
              : null;
            this.$router
              .push({
                name: "unit-map",
                query
              })
              .catch((err) => {});
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER"
          ) {
            this.$router.push({
              name: "home"
            });
          } else if (
            this.checkAbility({
              action: constants.LABELS.DASHBOARD,
              subject: "account_devices"
            }) &&
            localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER"
          ) {
            this.$router.push({
              name: "dashboard"
            });
          } else {
            try {
              Menus.forEach((element) => {
                if (
                  this.checkAbility({
                    action: element.action,
                    subject: element.resource
                  }) &&
                  element.route
                ) {
                  throw element;
                } else {
                  if (element && element.children && element.children.length) {
                    element.children.forEach((child) => {
                      if (
                        this.checkAbility({
                          action: child.action,
                          subject: child.resource
                        })
                      ) {
                        throw child;
                      }
                    });
                  }
                }
              });
            } catch (e) {
              if (e.route) {
                this.$router.push({ name: e.route });
              } else {
                // localStorage.setItem("TEMP_URL", "user-invitations");
                this.$router.push({ name: "user-invitations" });

                // if (oldPermission && oldPermission !== newPermission) {
                //   this.$router.go();
                // } else {
                //   this.$router.push({ name: "user-invitations" });
                // }
              }
            }
          }

          // this.$router.go();
          // this.showAcceptInvitationScreen();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    getAccounts() {
      try {
        this.accounts = this.accounts.map((l) => {
          l.variant = this.getRandomBgColor();
          l.avtar_txt = this.userAvatarTxt(l.account_name);

          return l;
        });
        const accountIds = this.accounts.map((e) => e.account_id);
        if (
          localStorage.getItem("USER_LOGIN_ID") === this.loggedUserId &&
          localStorage.getItem("ACCOUNT_ID") &&
          accountIds.includes(localStorage.getItem("ACCOUNT_ID"))
        ) {
          const lastSelectedAccount = this.accounts.find(
            (e) => e.account_id === localStorage.getItem("ACCOUNT_ID")
          );
          if (lastSelectedAccount && lastSelectedAccount.account_id) {
            localStorage.setItem(
              "USER_ACCOUNT_ID",
              lastSelectedAccount.account_id
            );

            localStorage.setItem("ACCOUNT_ID", lastSelectedAccount.account_id);
            localStorage.setItem(
              "USER_ACCOUNT_NAME",
              lastSelectedAccount.account_name
            );
            this.accountName = lastSelectedAccount.account_name;
            if (this.loggedUserId) {
              localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
            }
            localStorage.setItem("USER_ACCOUNT_TYPE", lastSelectedAccount.type);
            store.commit("account/setAccountType", lastSelectedAccount.type);

            this.whiteLabeling(lastSelectedAccount);
            this.isOldSelectedId = lastSelectedAccount.account_id;

            this.selectedUserAccount(lastSelectedAccount);
          }
        } else if (this.accounts && this.accounts.length === 0) {
          if (localStorage.getItem("USER_PERMISSIONS")) {
            localStorage.removeItem("USER_PERMISSIONS");
          }
          localStorage.removeItem("OLD_USER_ACCOUNT");
          localStorage.removeItem("ACCOUNT_ID");
          acl.updateAbility([]);
          store.commit("authorized/SET_IS_LOGIN", true);
          this.$router.push({ name: "user-invitations" });
        } else if (this.accounts && this.accounts.length <= 1) {
          if (this.accounts && this.accounts.length === 1) {
            localStorage.setItem(
              "USER_ACCOUNT_ID",
              this.accounts[0].account_id
            );
            localStorage.setItem("ACCOUNT_ID", this.accounts[0].account_id);
            localStorage.setItem(
              "USER_ACCOUNT_NAME",
              this.accounts[0].account_name
            );
            if (this.loggedUserId) {
              localStorage.setItem("USER_LOGIN_ID", this.loggedUserId);
            }

            localStorage.setItem("USER_ACCOUNT_TYPE", this.accounts[0].type);

            store.commit("account/setAccountType", this.accounts[0].type);
            this.whiteLabeling(this.accounts[0]);
            this.isOldSelectedId = this.accounts[0].account_id;
            this.accountName = this.accounts[0].account_name;
          }
          this.$bvModal.hide("modal-sm-switch-account");
          this.selectedUserAccount(this.accounts[0]);
          this.showAcceptInvitationScreen();
        } else {
          this.$bvModal.show("modal-sm-switch-account");
        }
        this.accounts.forEach((a) => {
          let accountId;
          if (localStorage.getItem("USER_ACCOUNT_ID")) {
            accountId = localStorage.getItem("USER_ACCOUNT_ID");
          }

          if (a.account_id === accountId) {
            this.isSelectedId = accountId;
            this.isOldSelectedId = accountId;
            this.whiteLabeling(a);
            localStorage.setItem(
              "USER_SUB_ACCOUNTS_RESTRICTED",
              a.restrict_sub_accounts
            );
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    getFilteredLists(filter, response) {
      response.data = Object.keys(response.data)
        .filter((key) => !filter.includes(key))
        .reduce((obj, key) => {
          obj[key] = response.data[key];
          return obj;
        }, {});
    },
    whiteLabeling(account) {
      localStorage.setItem(
        "USER_ACCOUNT_DETAILS",
        JSON.stringify(Object.assign({}, account))
      );
      if (account && account.white_labeling) {
        if (account.white_labeling.theme) {
          this.toggleBodyClass(null, account.white_labeling.theme);
        }
        if (account.white_labeling.lang && !localStorage.getItem("lang")) {
          this.$i18n.locale = account.white_labeling.lang;
          localStorage.setItem("lang", this.$i18n.locale);
          localeChanged();
        }
        store.commit("device/setIcons", []);
      }
    },
    async joinWithUserInvite(action) {
      try {
        this.show = true;
        const body = {
          token: this.inviteData.token,
          action: action
        };
        let response = await new AccountService().joinUserWithInvite(body);

        if (response && response.data) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `User invite ${
                action === "ACCEPT" ? "accepted" : "declined"
              } successfully`,
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          localStorage.removeItem("inviteData");
          this.isInvitation = false;
          this.getUserAccounts(1, 0);
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.$bvModal.hide("modal-accept-invite");
          this.isInvitation = false;
          localStorage.removeItem("inviteData");
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            text: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    showAcceptInvitationScreen() {
      const inviteData =
        localStorage.getItem("inviteData") &&
        JSON.parse(localStorage.getItem("inviteData"));
      const user =
        localStorage.getItem("USER_PROFILE_DETAILS") &&
        JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
      if (
        user &&
        inviteData &&
        user.email.toLowerCase() === inviteData.email.toLowerCase()
      ) {
        this.isInvitation = true;
        this.$bvModal.show("modal-accept-invite");
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.fill-primary {
  fill: var(--primary);
}
.get-app {
  color: #5e5873;
}
.dark-layout {
  .get-app {
    color: #b4b7bd;
  }
}
.blink1 {
  margin-right: 16px;
}
@media (max-width: 375px) {
  .d-flex.flex-sm-row {
    flex-direction: column !important;
    align-items: center !important;
    padding-left: 10px !important;
    padding-top: 1.5rem !important;
  }

  .d-flex.flex-sm-row b-link {
    margin-bottom: 10px;
  }
  .blink2 {
    margin-top: 10px;
  }
  .blink1 {
    margin-right: 0 !important;
  }
}
</style>
